<template>
  <MModal
    class="fill-upd-lock-modal"
    :class="{
      'fill-upd-lock-modal--blured': !isAllowClose,
    }"
    :prevent-outside-close="!isAllowClose"
    title="Заполните реестр УПД"
    lock-scroll
    is-close-btn-hidden
    @close="onClose"
  >
    <template #content>
      <p v-if="!updStatusDetails?.isNeedLockAllOrders">
        У вас есть незаполненные реализации за предыдущий квартал. Вам необходимо заполнить их до {{ updStatusDetails.allOrdersLockDate }}.
      </p>

      <p v-if="updStatusDetails?.isNeedRemind">
        Предупреждаем, что с {{ updStatusDetails.newOrdersLockDate }} вам будет заблокирована возможность работы с новыми заказами до заполнения реестра УПД.
      </p>

      <p v-if="updStatusDetails?.isNeedLockNewOrders">
        Мы заблокировали вам возможность обработки новых заказов, а с {{ updStatusDetails.allOrdersLockDate }} заблокируем доступ к любым заказам.
      </p>

      <template v-if="updStatusDetails?.isNeedLockAllOrders">
        <p>У вас есть незаполненные реализации за предыдущий квартал.</p>
        <p>
          Вам заблокирован доступ к заказам до заполнения реестра УПД.
        </p>
      </template>

      <div class="buttons">
        <MButton
          :size="MM.EButtonSizes.Large"
          full-width
          @click="onClickBtn"
        >
          Заполнить
        </MButton>
        <MButton
          v-if="isAllowClose"
          :size="MM.EButtonSizes.Large"
          :theme="MM.EButtonThemes.Secondary"
          full-width
          @click="onClose"
        >
          Позже
        </MButton>
      </div>
    </template>
  </MModal>
</template>

<script setup lang="ts">
import { MM, BFF } from 'types/index';

const props = defineProps<{
  updStatusDetails: BFF.Supplier.GetUpdFillingStatusDetailsResponse
}>();

const emit = defineEmits<{
  (e: 'fill'): void;
  (e: 'close'): void;
}>();

const isAllowClose = computed(() => {
  return !props.updStatusDetails.isNeedLockAllOrders;
});

async function onClickBtn() {
  emit('fill');
  await navigateTo('/supplier/documents/upd-registry/');
}

async function onClose() {
  if (isAllowClose.value) {
    emit('close');
  }
}
</script>

<style lang="scss">
.m-modal.fill-upd-lock-modal {
  z-index: 99999;

  &--blured {
    backdrop-filter: blur(5px);
    transition: 2s ease;
  }

  .m-modal__content {
    width: 550px;
  }
}
</style>

<style scoped>
.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
