import {
  IUpdRegistryHistory,
  IUpdRegistryUnfilledUpd,
} from 'models/supplier/supplierUpdRegistry.model';
import { API, BFF } from 'types';

export class DocumentsApiService {
    public static async getUpdRegistryTemplate(supplierId: number): Promise<IUpdRegistryHistory> {
      return AuthHelper.fetch<IUpdRegistryHistory>(`/api/v1/upd_registry/${supplierId}/template`, {
          method: 'GET',
      });
    }

    public static async getUpdRegistryActualHistory(supplierId: number): Promise<API.OrderService.SupplierUpdRegistryHistoryActualRetrieveSchema> {
      return AuthHelper.fetch<API.OrderService.SupplierUpdRegistryHistoryActualRetrieveSchema>(`/api/v1/supplier_upd_registry_history/${supplierId}/actual`, {
          method: 'GET',
      });
    }

    public static async getUpdRegistryStatus(supplierId: number): Promise<BFF.Supplier.GetUpdFillingStatusDetailsResponse> {
      return AuthHelper.fetch<IUpdRegistryUnfilledUpd>(`/api/v1/mp_upd_registry/${supplierId}/checkUpdFillingStatus`, {
        method: 'GET',
      });
    }

    public static async uploadUpdRegistry(supplierId: number, formData: FormData): Promise<IUpdRegistryHistory> {
      return AuthHelper.fetch<IUpdRegistryHistory>(`/api/v1/supplier_upd_registry/${supplierId}`, {
          method: 'PUT',
          body: formData,
      });
    }
}
