import { useUserStore } from 'store/user.store';
import { UserHelper } from 'utils/userHelper.util';
import Loader from 'shared/utils/loaderHelper.util';
import Notificator from 'shared/services/notificator.service';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { DocumentsApiService } from 'services/api/documentsApi.service';
import { DateFormat } from 'shared/utils/dateFormat.util';
import { MModalsManager } from '@maksmart/maksify/dist/services';
import FillUpdLockModal from 'components/modals/FillUpdLockModal.vue';
import { BFF } from 'types';

/* Вынес за пределы функции, чтобы не пилить целый сервис ради одного рефа */
const unfilledUpdDetails = ref<BFF.Supplier.GetUpdFillingStatusDetailsResponse>();

export function useSupplierUnfilledUpdRegistry() {
    const userStore = useUserStore();
    const loadingUnfilledUpd = Loader.getReactiveInstance();

    const dateNow = new Date();
    const deadlineFillUpdDate = ref<Date | undefined>();

    const monthFillingPeriod = computed(
      () => deadlineFillUpdDate.value
        ? DateFormat.month(new Date(dateNow.getFullYear(), deadlineFillUpdDate.value.getMonth() - 1)).toLowerCase()
        : undefined,
    );

    function isAllowUpdRegistry(): boolean {
        return UserHelper.isSupplier && SupplierHelper.isQualificationCompleted;
    }

    function isUpdLoadPage(): boolean {
      const route = useRoute();
      return route?.name === 'supplier-documents-index-upd-registry';
    }

    async function checkUnfilledUpd(isNeedWarningModal = false): Promise<void> {
        if (!isAllowUpdRegistry() || userStore.supplierId == null) {
            return;
        }

        loadingUnfilledUpd.activate();

        try {
            unfilledUpdDetails.value = await DocumentsApiService.getUpdRegistryStatus(userStore.supplierId);
            const isNeedLock = unfilledUpdDetails.value?.isNeedLockNewOrders || unfilledUpdDetails.value?.isNeedLockAllOrders;
            if (isNeedLock) {
                isNeedWarningModal && (await showWarningLockModal());
            }
        } catch (error) {
            clientSentry.captureException(error);
            Notificator.showDetachedNotification('Произошла ошибка при запросе незаполненных УПД');
        } finally {
            loadingUnfilledUpd.deactivate();
        }
    }

    async function showWarningLockModal() {
      if (!isUpdLoadPage()) {
        const modalId = 'upd-fill-lock-modal';
        await MModalsManager.show(
          modalId,
          {
            updStatusDetails: unfilledUpdDetails.value,
          },
          {
            closeOther: true,
            component: FillUpdLockModal,
            on: {
              fill() {
                MModalsManager.hide(modalId);
              },
              close() {
                MModalsManager.hide(modalId);
              },
            },
          },
        );
      }
    }

    return {
        checkUnfilledUpd,
        deadlineFillUpdDate,
        monthFillingPeriod,
        unfilledUpdDetails,
    };
}
